const columnsIndex = {
  name: 0,
  authors: 1,
  year: 2,
  type: 3,
  venue: 4,
  target: 5,
  notes: 6,
  doi: 7,
  ieeeXplore: 8,
  ieeeSociety: 9,
  acm: 10,
  scienceDirect: 11,
  springer: 12,
  openAcess: 13,
  preprint: 14,
  keywords: 15,
  abstract: 16,
};

export default columnsIndex;
