import "./App.css";
import columnsIndex from "shared/constants";
import React, { useEffect, useState } from "react";
import { readString } from "react-papaparse";

import Header from "components/Header";
import Table from "components/Table";
import CloudKeywords from "components/WordCloudKeywords";
import CloudAuthors from "components/WordCloudAuthors";

import file from "./papers-gse.csv";

function App() {
  const [data, setData] = useState([]);
  useEffect(() => {
    readString(file, {
      download: true,
      header: true,
      complete: (results, file) => {
        setData(results.data);
        console.log("Parsing complete:", results, file);
      },
      error: (error, file) => {
        console.log("Error while parsing:", error, file);
      },
    });
  }, []);

  const transform = data.map((item) => {
    return Object.values(item);
  });

  return (
    <div className="Body">
      <Header />
      <div
        style={{
          maxWidth: "100%",
          maxHeight: "400",
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          padding: "3em 0",
        }}
      >
        <img src="/wordcloud.png" alt="" width="100%" />
      </div>
      <div className="Header" style={{ marginTop: -100 }}>
        Word cloud using react-wordcloud and the papers' keywords
      </div>
      <Table data={transform} />
    </div>
  );
}

export default App;
