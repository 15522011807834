import { createTheme } from "@mui/material/styles";

const primaryColor = "#f4a900";
const backgroundColor = "#0b142f";
const textColor = "#fff";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: primaryColor,
    },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundColor,
          boxShadow: "none",
          backgroundImage: "none",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: textColor,
          borderBottom: `solid ${primaryColor} 5px`,
          fontSize: "1.4rem",
        },
        head: {
          backgroundColor: primaryColor,
          color: backgroundColor,
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          fontSize: "1.1em",
        },
      },
    },
    MUIDataTableHeadRow: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: "1em",
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          backgroundColor: primaryColor,
        },
        sortActive: {
          color: backgroundColor,
        },
        root: {
          "& .MUITableSortLabel-icon": {
            color: primaryColor,
          },
        },
        data: {
          fontSize: "1.1em",
          fontWeight: "bold",
          textTransform: "Uppercase",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          MuiTableSortLabel: {
            root: {
              "&.Mui-active .MuiTableSortLaber-icon": {
                color: primaryColor,
              },
            },
          },
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        icon: {
          color: textColor,
          "&:hover": {
            color: primaryColor,
          },
        },
        filterPaper: {
          boxShadow:
            "0px 24px 38px 3px hsla(0,0%,0%,0.14), 0px 9px 46px 8px hsla(0,0%,0%,0.12), 0px 11px 15px -7px hsla(0,0%,0%,0.2)",
          borderRadius: "1em",
        },
      },
    },

    MUIDataTableFilter: {
      styleOverrides: {
        root: {
          backgroundColor: backgroundColor,
          color: primaryColor,
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          backgroundColor: primaryColor,
        },
      },
    },
  },
});

export default darkTheme;
