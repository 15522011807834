import "styles/Table.css";

import React from "react";

import MUIDataTable from "mui-datatables";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import { IconContext } from "react-icons";
import { BsFileEarmarkTextFill } from "react-icons/bs";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { ThemeProvider } from "@mui/material/styles";
import darkTheme from "styles/DarkMode.js";

import columnsIndex from "shared/constants";

const muiCache = createCache({
  key: "mui",
  prepend: true,
});

function Table({ data }) {
  const titleText = (value, tableMeta, updateValue) => {
    return <b>{value}</b>;
  };

  const textAlignCenter = (value, tableMeta, updateValue) => {
    return <p style={{ textAlign: "center" }}>{value}</p>;
  };
  const buttonDOI = (value, tableMeta, updateValue) => {
    return value === "" ? (
      ""
    ) : (
      <center>
        <a className="Button" href={value} rel="noreferrer" target="_blank">
          <IconContext.Provider value={{ color: "white" }}>
            <BsFileEarmarkTextFill />
          </IconContext.Provider>
        </a>
      </center>
    );
  };

  const expandableRow = (rowData, rowMeta) => {
    const colSpan = rowData.length + 1;
    if (rowData[columnsIndex["abstract"]] === "") {
      return (
        <TableRow>
          <TableCell colSpan={colSpan}>
            <br />
            <br />
            <center>
              <b>NOT AVAILABLE</b>
            </center>
            <br />
            <br />
          </TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          <b>Abstract:</b>
          <br />
          <p className="Abstract">{rowData[columnsIndex["abstract"]]}</p>
          <br />
          <b>Keywords:</b> {rowData[columnsIndex["keywords"]]}
          <br />
          <br />
          <div className="Links">
            <b>Available Links:</b>&nbsp;
            {rowData[columnsIndex["ieeeXplore"]]}
            {rowData[columnsIndex["ieeeSociety"]]}
            {rowData[columnsIndex["acm"]]}
            {rowData[columnsIndex["scienceDirect"]]}
            {rowData[columnsIndex["springer"]]}
            {rowData[columnsIndex["openAcess"]]}
            {rowData[columnsIndex["preprint"]]}
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const columns = [
    { name: "Paper", options: { filter: false, customBodyRender: titleText } },
    { name: "Author", options: { filter: false } },
    {
      name: "Year",
      options: {
        customBodyRender: textAlignCenter,
      },
    },
    { name: "Type", options: { display: false } },
    "Venue",
    { name: "Target", options: { display: false } },
    {
      name: "Notas",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "DOI",
      options: {
        filter: false,
        customBodyRender: buttonDOI,
      },
    },
    {
      name: "Link IEEE Xplore",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return createButtonIconLink(value, "/ieeex.png", "IEEE Explore");
        },
      },
    },
    {
      name: "Link IEEE Society",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return createButtonIconLink(value, "/ieees.png", "IEEE Society");
        },
      },
    },

    {
      name: "Link ACM",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return createButtonIconLink(value, "/acm.png", "ACM");
        },
      },
    },
    {
      name: "Science Direct",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return createButtonIconLink(value, "/science.jpg", "Science Direct");
        },
      },
    },
    {
      name: "Springer",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return createButtonIconLink(value, "/springer.png", "Springer");
        },
      },
    },
    {
      name: "Open Access",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return createButtonIconLink(value, "/open_source.jpg", "Open Source");
        },
      },
    },
    {
      name: "Preprint",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return createButtonIconLink(value, "/preprint.png", "Preprint");
        },
      },
    },
    {
      name: "Keywords",
      options: {
        filter: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "Abstract",
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
  ];

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    tableBodyHeight: "100%",
    expandableRowsOnClick: true,
    expandableRows: true,
    expandableRowsHeader: false,
    selectableRowsHideCheckboxes: false,
    selectableRows: "none",
    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    renderExpandableRow: expandableRow,
    selectableRowsHeader: false,
    sortOrder: {
      name: "Year",
      direction: "desc",
    },
    rowsPerPage: 100,
  };

  return (
    <div className="Table">
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={darkTheme}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

function createButtonIconLink(value, icon, altText) {
  return value === "" ? (
    ""
  ) : (
    <center>
      <a href={value} rel="noreferrer" target="_blank">
        <img id="logo" src={"/icons" + icon} alt={altText} height="40" />
      </a>
      &nbsp;
    </center>
  );
}

export default Table;
