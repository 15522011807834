import "styles/Header.css";

function Header() {
  return (
    <div className="Header">
      <h1>Game Software Engineering</h1>
      <p style={{ fontWeight: 400 }}>
        Game Software Engineering is a branch of Software Engineering that
        focuses on the development of video games.
        <a href="https://svit.usj.es/Chueca_IST_2023_PRE" className="Link">
          “The Consolidation of Game Software Engineering”
        </a>{" "}
        paper (Information and Software Technology Journal, 2023) is a
        Systematic Literature Review that identifies 13 potential future
        research directions for this domain: (1) Cross-fertilization between GSE
        and MDE (2) Game Software Product Lines, (3) The Blossoming of
        Publishing Platforms, (4) The Multifaceted Nature of GSE, (5) Planning
        and Scheduling Issues, (6) The Consequences of Games as a Service, (7)
        GSE Security, (8) Weaving Software and Art Design, (9) New Wave of
        Requirements Research for GSE, (10) Testing and Debugging in the Face of
        Gameplay Subjectivity, (11) Green GSE, (12) Search-Based GSE, and (13)
        Content Creation and Artificial Intelligence. This page shows the Word
        Cloud and lists the studies included in the review.
      </p>

      <div className="subheader">
        <div>
          An initiative by{" "}
          <a href="https://svit.usj.es/" rel="noreferrer" target="_blank">
            <img
              id="logo"
              src="Logo.svg"
              alt="SVIT Research Group"
              height="40"
            />
          </a>
        </div>

        <a href="mailto:ccetina@usj.es" className="btn">
          Join us
        </a>
      </div>
    </div>
  );
}

export default Header;
